<template>
    <div>
        <CDataTable
            :items="addresses"
            :fields="addressFields"
            :items-per-page="10"
            :loading="loading"
            class="modalTable"
            hover
            pagination
        >
        <template #country="{item}">
          <td> {{ item.country.name }}</td>
        </template>
        </CDataTable> 
    </div>
</template>

<script>
    import moment from 'moment';
    export default{
        name: "CustomerAdresses",
        props:{
            params: Object,
        },
        computed:{
            addresses: function() {
                return this.$store.getters.customerAddresses
            },
            loading: function() {
                return this.$store.getters.socialLoading
            },
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                addressFields: [
                  { key: 'addressLine', label: 'Adres', _style: 'font-size:12px' },
                  { key: 'city', label: 'İl', _style: 'font-size:12px' },
                  { key: 'district', label: 'İlçe', _style: 'font-size:12px' },
                  { key: 'country', label: 'Ülke', _style: 'font-size:12px' }
                ],
            }
        },
        methods: {
            dateFormat(date){
                return moment(date).format("DD.MM.YYYY HH:mm:ss")
            },
        }
    }
</script>